<template>
  <common-dropdown
    placeholder="Bulk Actions"
    variant="secondary"
    :button="{
      label: numberOfFilters ? `Filters (${numberOfFilters})` : 'Filters',
      width: 'w-32',
      variant: 'tertiary',
    }"
    maxHeight
    width="w-52"
    custom>
    <template slot="icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="text-grey"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
      </svg>
    </template>

    <template slot="custom">
      <div class="w-full text-right px-4 pt-2 pb-1">
        <p
          class="text-blue hover:text-blue-500 font-semibold text-sm cursor-pointer"
          @click="resetMenu">
          Clear
        </p>
      </div>
      <div class="px-4 text-sm max-h-80 overflow-auto">
        <div class="relative py-3 border-b">
          <div
            class="flex items-center justify-between cursor-pointer"
            @click="toggleSubmenu(0)">
            Profile
            <svg
              v-if="submenuOpened[0]"
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 15l7-7 7 7" />
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              class="text-grey h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7" />
            </svg>
          </div>
          <div
            :class="{
              'h-max': submenuOpened[0],
              'h-0 hidden': !submenuOpened[0],
            }">
            <div
              href="javascript:void(0)"
              class="text-gray-700 flex items-center w-full py-1 leading-5 text-left"
              role="menuitem">
              <input
                type="checkbox"
                class="mr-2"
                :checked="selectedFilters['profile']['visible']"
                :value="selectedFilters['profile']['visible']"
                @click="updateFilter('profile', 'visible', true)" />
              Visible
            </div>
            <div
              href="javascript:void(0)"
              class="text-gray-700 flex items-center w-full py-1 leading-5 text-left"
              role="menuitem">
              <input
                type="checkbox"
                class="mr-2"
                :checked="selectedFilters['profile']['notVisible']"
                :value="selectedFilters['profile']['notVisible']"
                @click="updateFilter('profile', 'notVisible', true)" />
              Not Visible
            </div>
            <div
              href="javascript:void(0)"
              class="text-gray-700 flex items-center w-full py-1 leading-5 text-left"
              role="menuitem">
              <input
                type="checkbox"
                class="mr-2"
                :checked="selectedFilters['profile']['questionsAnswered']"
                :value="selectedFilters['profile']['questionsAnswered']"
                @click="updateFilter('profile', 'questionsAnswered', true)" />
              Questions Answered
            </div>
            <div
              href="javascript:void(0)"
              class="text-gray-700 flex items-center w-full py-1 leading-5 text-left"
              role="menuitem">
              <input
                type="checkbox"
                class="mr-2"
                :checked="selectedFilters['profile']['questionsNotAnswered']"
                :value="selectedFilters['profile']['questionsNotAnswered']"
                @click="
                  updateFilter('profile', 'questionsNotAnswered', true)
                " />
              Questions Unanswered
            </div>
          </div>
        </div>
        <div class="relative py-3 border-b">
          <div
            class="flex items-center justify-between cursor-pointer"
            @click="toggleSubmenu(1)">
            Call Requests
            <svg
              v-if="submenuOpened[1]"
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 15l7-7 7 7" />
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              class="text-grey h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7" />
            </svg>
          </div>
          <div
            :class="{
              'h-max': submenuOpened[1],
              'h-0 hidden': !submenuOpened[1],
            }">
            <div
              href="javascript:void(0)"
              class="text-gray-700 flex items-center w-full py-1 leading-5 text-left"
              role="menuitem">
              <input
                type="checkbox"
                class="mr-2"
                :checked="
                  selectedFilters['callRequests']['callRequestsEnabled']
                "
                :value="selectedFilters['callRequests']['callRequestsEnabled']"
                @click="
                  updateFilter('callRequests', 'callRequestsEnabled', true)
                " />
              Enabled
            </div>
            <div
              href="javascript:void(0)"
              class="text-gray-700 flex items-center w-full py-1 leading-5 text-left"
              role="menuitem">
              <input
                type="checkbox"
                class="mr-2"
                :checked="
                  selectedFilters['callRequests']['callRequestsDisabled']
                "
                :value="selectedFilters['callRequests']['callRequestsDisabled']"
                @click="
                  updateFilter('callRequests', 'callRequestsDisabled', true)
                " />
              Disabled
            </div>
          </div>
        </div>
        <div class="relative py-3">
          <div
            class="flex items-center justify-between cursor-pointer"
            @click="toggleSubmenu(2)">
            Status
            <svg
              v-if="submenuOpened[2]"
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 15l7-7 7 7" />
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              class="text-grey h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7" />
            </svg>
          </div>
          <div
            :class="{
              'h-max': submenuOpened[2],
              'h-0 hidden': !submenuOpened[2],
            }">
            <div
              href="javascript:void(0)"
              class="text-gray-700 flex items-center w-full py-1 leading-5 text-left"
              role="menuitem">
              <input
                type="checkbox"
                class="mr-2"
                :checked="selectedFilters['status']['active']"
                :value="selectedFilters['status']['active']"
                @click="updateFilter('status', 'active', true)" />
              Active
            </div>
            <div
              href="javascript:void(0)"
              class="text-gray-700 flex items-center w-full py-1 leading-5 text-left"
              role="menuitem">
              <input
                type="checkbox"
                class="mr-2"
                :checked="selectedFilters['status']['invited']"
                :value="selectedFilters['status']['invited']"
                @click="updateFilter('status', 'invited', true)" />
              Invited
            </div>
            <div
              href="javascript:void(0)"
              class="text-gray-700 flex items-center w-full py-1 leading-5 text-left"
              role="menuitem">
              <input
                type="checkbox"
                class="mr-2"
                :checked="selectedFilters['status']['archived']"
                :value="selectedFilters['status']['archived']"
                @click="updateFilter('status', 'archived', true)" />
              Archived
            </div>
            <div
              href="javascript:void(0)"
              class="text-gray-700 flex items-center w-full py-1 leading-5 text-left"
              role="menuitem">
              <input
                type="checkbox"
                class="mr-2"
                :checked="selectedFilters['status']['new']"
                :value="selectedFilters['status']['new']"
                @click="updateFilter('status', 'new', true)" />
              New
            </div>
          </div>
        </div>
        <div class="py-4 sticky bottom-0 bg-white">
          <common-button size="sm" @click="applyFilters" :disabled="isDisabled"
            >Apply</common-button
          >
        </div>
      </div>
    </template>
  </common-dropdown>
</template>

<script>
  import { eventBus } from "@/main.js";

  export default {
    data() {
      return {
        submenuOpened: {},
        tempNumberOfFilters: 0,
        numberOfFilters: 0,
        selectedFilters: {
          profile: {},
          callRequests: {},
          status: {},
        },
      };
    },
    methods: {
      toggleSubmenu(menu) {
        this.$set(this.submenuOpened, menu, !this.submenuOpened[menu]);
      },
      updateFilter(section, filter, value) {
        if (filter in this.selectedFilters[section]) {
          this.$delete(this.selectedFilters[section], filter);
          this.tempNumberOfFilters -= 1;
        } else {
          this.$set(this.selectedFilters[section], filter, value);
          this.tempNumberOfFilters += 1;
        }
      },
      applyFilters() {
        this.$emit("update-filter", this.selectedFilters);
        this.numberOfFilters = this.tempNumberOfFilters;
        eventBus.$emit("toggle-dropdown", false);
      },
      resetMenu() {
        this.selectedFilters = {
          profile: {},
          callRequests: {},
          status: {},
        };

        this.tempNumberOfFilters = 0;
        this.applyFilters();
      },
    },
    computed: {
      isDisabled() {
        return (
          !Object.keys(this.selectedFilters.profile).length &&
          !Object.keys(this.selectedFilters.callRequests).length &&
          !Object.keys(this.selectedFilters.status).length
        );
      },
    },
  };
</script>
